<template>
  <el-row class="table">
    <span class="title">问题重判</span>
    <el-row class="row-bg" style="height: 60px;">

      <el-col :span="4" align="center">
        <el-select v-model="questionTag" @change="handleChange()" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" :offset="1" align="center">
        <el-input  size="medium" v-model="questionId"  placeholder="输入问题id" ></el-input>
      </el-col>

      <el-col :span="5"  :offset="1">
        <el-date-picker
          v-if="questionTag == '2'"
          v-model="pickerTime"
          type="datetimerange"
          :picker-options="pickerOptions2"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          align="right">
        </el-date-picker>
      </el-col>
      <el-col :span="1" :offset="2">
        <el-button type="warning" @click="redetermine()" plain>重判</el-button>
      </el-col>

    </el-row>
  </el-row>
</template>

<script>
  import { resetSolution } from '@/api/research/curriculum'
    export default {
        name: "redetermine-subject",
      data(){
          return {
            options: [{
              value: '1',
              label: '按照问题重判'
            }, {
              value: '2',
              label: '按照问题&日期重判'
            }],
            pickerOptions2: {
              shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近一个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近三个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
                }
              }]
            },
            pickerTime: '',
            questionTag:"1",
            questionId: ''
          }
      },
      created() {

      },
      methods: {
        viterfyNumber(str){
          if (str !== null && str !== '' && str.trim() !== ''){
            var pattern=new RegExp("^[0-9]*$");
            return pattern.test(str);
          }
          else{
            return false;
          }
        },
        handleChange(){
          this.questionId = ''
        },
        redetermine(){
          this.submitresetSolution()
        },

        async submitresetSolution () {
          if(!this.viterfyNumber(this.questionId)) {
            $msg('请填写正确的问题ID',1)
            return  false
          }
          if(this.questionTag === '2') {
            if(this.pickerTime === '') {
              $msg('请选择日期',1)
              return false
            }
          }
          var res
          if(this.questionTag === '1') {
            res = await resetSolution({
              problem_id: this.questionId
            })
          }else {
            res = await resetSolution({
              problem_id: this.questionId,
              startTime:this.pickerTime [0],
              endTime:this.pickerTime [1]
            })
          }
          if(res && res.state === 'success') {
            $msg("重判成功",0)
          }else {
            $msg("重判失败",1)
          }
        }
      }
    }
</script>

<style scoped lang="scss">
  @import "../../style/table.scss";
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button{
    -webkit-appearance: none !important;
  }
  input[type="number"]{
    -moz-appearance:textfield;
  }
</style>
