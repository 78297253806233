var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("问题重判")]),
      _c(
        "el-row",
        { staticClass: "row-bg", staticStyle: { height: "60px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 4, align: "center" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: {
                    change: function ($event) {
                      return _vm.handleChange()
                    },
                  },
                  model: {
                    value: _vm.questionTag,
                    callback: function ($$v) {
                      _vm.questionTag = $$v
                    },
                    expression: "questionTag",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 3, offset: 1, align: "center" } },
            [
              _c("el-input", {
                attrs: { size: "medium", placeholder: "输入问题id" },
                model: {
                  value: _vm.questionId,
                  callback: function ($$v) {
                    _vm.questionId = $$v
                  },
                  expression: "questionId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 5, offset: 1 } },
            [
              _vm.questionTag == "2"
                ? _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "picker-options": _vm.pickerOptions2,
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      align: "right",
                    },
                    model: {
                      value: _vm.pickerTime,
                      callback: function ($$v) {
                        _vm.pickerTime = $$v
                      },
                      expression: "pickerTime",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1, offset: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.redetermine()
                    },
                  },
                },
                [_vm._v("重判")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }